import {withNgxsFormPlugin} from '@ngxs/form-plugin';
import {withNgxsStoragePlugin} from '@ngxs/storage-plugin';
import type {Environment} from './environment.interface';

export const environment: Environment = {
  auth: 'https://keycloak-beige.12stz.dev/auth',
  backend: 'https://feedbacks.12stz.dev/api/v1',
  ngxsPlugins: [withNgxsFormPlugin(), withNgxsStoragePlugin({keys: []})],
  production: true,
  name: 'stage',
};
